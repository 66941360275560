body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Söhne', ui-sans-serif, system-ui,
    'Ubuntu', 'Cantarell', 'Segoe UI', 'Droid Sans', 'Noto Sans', 'Roboto', 'Arial', 'Open Sans',
    sans-serif, 'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16;
  font-weight: normal;
  line-height: 28px;
  margin-bottom: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: -apple-system, BlinkMacSystemFont, 'Söhne', ui-sans-serif, system-ui,
  'Ubuntu', 'Cantarell', 'Segoe UI', 'Droid Sans', 'Noto Sans', 'Roboto', 'Arial', 'Open Sans',
  sans-serif, 'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 32px;
}

h2 {
  font-family: -apple-system, BlinkMacSystemFont, 'Söhne', ui-sans-serif, system-ui,
  'Ubuntu', 'Cantarell', 'Segoe UI', 'Droid Sans', 'Noto Sans', 'Roboto', 'Arial', 'Open Sans',
  sans-serif, 'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 24;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
}

h3 {
  font-family: -apple-system, BlinkMacSystemFont, 'Söhne', ui-sans-serif, system-ui,
  'Ubuntu', 'Cantarell', 'Segoe UI', 'Droid Sans', 'Noto Sans', 'Roboto', 'Arial', 'Open Sans',
  sans-serif, 'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 8px;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, 'Söhne', ui-sans-serif, system-ui,
  'Ubuntu', 'Cantarell', 'Segoe UI', 'Droid Sans', 'Noto Sans', 'Roboto', 'Arial', 'Open Sans',
  sans-serif, 'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 16;
  font-weight: normal;
  line-height: 28px;
  margin-bottom: 20px;
}

a {
  color: #C1C1C1;
  text-decoration: none;
}
a:hover {
   color: #C1C1C1
}